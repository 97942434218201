<template>
  <TheHeader v-if="showHeader" />

  <!-- HELP -->
  <!-- <HelpBottomSheet v-if="!isDesktop" v-model="helpStore.isHelpOpen" /> -->
  <!-- AUTH -->
  <AuthBottomSheet
    v-if="!isDesktop && !authStore.loggedIn"
    v-model="authStore.authFormIsOpen"
  />
  <!-- PUSH NOTIFICATION -->
  <PromptPushNotificationModal
    v-model="fcmNotification.open"
    :isLoading="isSubscribeUserLoading"
    @allow="requestNotificationPermission"
    @later="disallowPushNotifications"
  />
  <!-- LOGOUT -->
  <LogoutModal
    v-if="authStore.loggedIn"
    v-model="authStore.logoutModalIsOpen"
  />

  <HelpPopup />

  <TopAlerts v-if="!isDesktop" />

  <PromptAddAppToHome
    v-if="isMobile && !isPWAInstalled"
    v-model="isOpenPromptAddAppToHome"
    @update:model-value="onUpdatePromptAddAppToHome"
  />

  <main
    class="min-h-screen lg:p-0"
    :class="[
      gapFromBottom,
      {
        'px-16 py-4': $route.name !== 'Search' && !isDesktop,
      },
    ]"
  >
    <!-- 'relative md:z-0 md:-mt-[103px]': $route.name === 'Search' && !isMobile, -->
    <slot />
  </main>

  <component :is="footerComponent" v-if="checkRenderBottomNavigation()" />
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useToggle } from '@vueuse/core'
import { PushNotificationAnswerState } from '../types/model/userNotificationPreferences'
import useUserPreferencesStore from '../store/userPreferences'
import TheBottomNavigation from '@/modules/Core/components/layout/BottomNavigation/TheBottomNavigation.vue'
import TheFooter from '@/modules/Core/components/layout/Footer/TheFooter.vue'
import TheHeader from '@/modules/Core/components/layout/Header/TheHeader.vue'
import HelpPopup from '@/modules/Help/HelpPopup/HelpPopup.vue'
import TopAlerts from '@/modules/Core/components/partials/TopAlerts/TopAlerts.vue'
import {
  useDevicePlatform,
  usePushNotification,
} from '@/modules/Core/composables'
import useAuthStore from '@/modules/Auth/store'

defineOptions({
  name: 'TheDefaultLayout',
})

const AuthBottomSheet = defineAsyncComponent(
  () => import('@/modules/Auth/components/AuthBottomSheet/AuthBottomSheet.vue')
)
const PromptPushNotificationModal = defineAsyncComponent(
  () =>
    import(
      '@/modules/Core/components/shared/PromptPushNotificationModal/PromptPushNotificationModal.vue'
    )
)
const LogoutModal = defineAsyncComponent(
  () => import('@/modules/Auth/components/LogoutModal/LogoutModal.vue')
)
const PromptAddAppToHome = defineAsyncComponent(
  async () =>
    await import(
      '@/modules/Core/components/partials/PromptAddAppToHome/PromptAddAppToHome.vue'
    )
)

// AUTH
const authStore = useAuthStore()

const { isDesktop, isMobile, isPWAInstalled } = useDevicePlatform()

// FOOTER
const footerComponent = computed(() =>
  isDesktop.value ? TheFooter : TheBottomNavigation
)

const gapFromBottom = computed(
  () => !isDesktop.value && checkRenderBottomNavigation() && 'pb-[20vh]'
)

const route = useRoute()

const showHeader = computed(() => {
  if (!isDesktop.value) {
    return false
  }

  return route.meta?.header ?? true
})

// PUSH NOTIFICATION
const userPreferencesStore = useUserPreferencesStore()
const [isOpenPromptAddAppToHome, setIsOpenPromptAddAppToHome] = useToggle(true)

async function getPermission() {
  return Notification && (await Notification.requestPermission())
}

const {
  requestNotificationPermission,
  fcmNotification,
  isSubscribeUserLoading,
  isSupportedFCM,
} = usePushNotification()

async function handleNotificationAnswer() {
  const hasPermission = await getPermission()

  if (hasPermission === 'denied') {
    fcmNotification.value.open = false
    return
  }

  switch (fcmNotification.value.answer) {
    case PushNotificationAnswerState.NOT_ANSWERED:
      fcmNotification.value.open = true
      return
    case PushNotificationAnswerState.LATER:
      return
    default:
      if (!fcmNotification.value.operationComplete) {
        requestNotificationPermission()
      }
  }
}

watch(
  [() => authStore.loggedIn, () => isSupportedFCM.value],
  ([isLoggedIn, supported]) => {
    if (
      isMobile.value &&
      !userPreferencesStore.isConfirmAddToHomeScreen &&
      !isPWAInstalled.value
    ) {
      setIsOpenPromptAddAppToHome(true)
    }

    if (!isLoggedIn) {
      return
    }

    if (supported) {
      handleNotificationAnswer()
    }
  },
  {
    immediate: true,
  }
)

function onUpdatePromptAddAppToHome() {
  setIsOpenPromptAddAppToHome(false)
}

function disallowPushNotifications() {
  fcmNotification.value.open = false
  fcmNotification.value.answer = PushNotificationAnswerState.LATER
}

function hideAuthFormWhenAuthBottomSheetIsClosed(value: boolean) {
  if (value) {
    return
  }

  authStore.setAuthFormIsDisplayed(false)
}

watch(() => authStore.authFormIsOpen, hideAuthFormWhenAuthBottomSheetIsClosed)

function checkRenderBottomNavigation() {
  if (isDesktop.value) {
    return true
  }

  return route.meta?.showBottomNav ?? true
}
</script>
