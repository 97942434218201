<template>
  <TheAppLayout />
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { onMounted, watch } from 'vue'
import useAuthStore from '@/modules/Auth/store'
import { useUserPreferencesQuery } from '@/modules/Core/requests/queries'
import TheAppLayout from '@/modules/Core/layouts/TheAppLayout.vue'
import useLocale from '@/modules/Core/composables/useLocale'
import useUserPreferencesStore from '@/modules/Core/store/userPreferences'
import useItinerarySummary from '@/modules/Search/store/itinerarySummary'
import { useItinerary } from '@/modules/Search/composables'
import { useUpdateServiceWorker } from '@/modules/Core/composables'

useLocale()

const { registerServiceWorker } = useUpdateServiceWorker()
onMounted(() => {
  if (!import.meta.env.DEV) {
    registerServiceWorker()
  }
})

useHead({
  title: 'TimeTravel',
})

// ITINERARY
const itinerarySummaryStore = useItinerarySummary()
const { fetchItinerary } = useItinerary()
const { data: itierariesData, isFetching: itierariesIsLoading } =
  fetchItinerary()

watch(itierariesData, () => {
  if (!itierariesData.value?.data) {
    return
  }

  itinerarySummaryStore.setItineraries(itierariesData.value.data)
})

watch(
  itierariesIsLoading,
  (value) => {
    itinerarySummaryStore.itinerariesIsLoading = value
  },
  {
    immediate: true,
  }
)

// USER PREFERENCES
const authStore = useAuthStore()

const { data: userPreferencesData, refetch } = useUserPreferencesQuery({
  enabled: false,
})

const userPreferencesStore = useUserPreferencesStore()

function fetchUserPreferencesIfUserIsLoggedIn() {
  if (!authStore.loggedIn) {
    return
  }

  refetch()
}

onMounted(fetchUserPreferencesIfUserIsLoggedIn)

watch(userPreferencesData, () => {
  if (!userPreferencesData.value?.data) {
    return
  }

  userPreferencesStore.setNotificationPreferences(
    userPreferencesData.value?.data.notification
  )
})

// LANGUAGE
const { applyCurrentLanguage } = useLocale()
onMounted(applyCurrentLanguage)
</script>
