<template>
  <header
    class="fixed left-0 top-0 w-full transform bg-white shadow transition-transform duration-300 ease-in-out print:hidden md:z-1"
    :style="{ transform: `translateY(${translateY}px)` }"
  >
    <TopAlerts />

    <JContainer class="py-12">
      <nav
        ref="headerNavRef"
        class="mx-auto flex items-center justify-between"
        aria-label="Global"
      >
        <div class="flex lg:flex-1">
          <JLogo :title="brandTitle" src="/logo/timeTravel.svg" />
        </div>

        <div v-if="isDesktop" class="flex">
          <ul class="me-40 flex items-center gap-x-24">
            <li v-for="item in localizedHeaderItems" :key="item.title">
              <button
                v-if="
                  (item.noAuth || authStore.loggedIn) && item.event !== 'help'
                "
                class="text-subject font-medium leading-6 text-dark"
                @click="onClickItem(item.event, item)"
              >
                {{ item.title }}
              </button>

              <button
                v-if="item.event === 'help'"
                class="text-subject font-medium leading-6 text-dark"
                @click="helpStore.toggleHelpModal(true)"
              >
                {{ item.title }}
              </button>
            </li>
          </ul>

          <div class="flex items-center gap-x-12">
            <HeaderContentProfile
              v-if="showProfile && authStore.loggedIn"
              @logout="emitLogout"
            />

            <button v-else-if="showProfile" @click="emitShowProfile">
              <JIcon name="user-outline" />
            </button>

            <HeaderContentCurrencyMenu v-if="showCurrency" />

            <div
              class="h-24 origin-top-left rounded-md border border-gray-400"
            />

            <!--            <HeaderContentLanguageMenu v-if="showMultiLang" /> -->

            <!--            <div -->
            <!--              class="h-24 origin-top-left rounded-md border border-gray-400" -->
            <!--            /> -->

            <button @click="emitContactUs">
              <JIcon name="support" />
            </button>

            <ItinerarySummaryToggler v-if="$route.name === 'Search'">
              <JButton
                color="primary"
                class="relative ml-4 rounded-md px-8 text-white"
                icon="navigation"
                @click="$emit('showItinerary')"
              >
                <span>
                  {{ $t('search.itinerarySummary.title') }}
                </span>

                <span
                  v-if="itineraryLength"
                  class="absolute -right-8 -top-8 flex h-24 w-24 items-center justify-center rounded-full bg-danger text-metadata-limited font-medium"
                >
                  {{ itineraryLength }}
                </span>
              </JButton>
            </ItinerarySummaryToggler>
          </div>
        </div>
      </nav>
    </JContainer>

    <Transition
      enterFromClass="opacity-0 -translate-y-4"
      enterActiveClass="transition duration-20"
      enterToClass="opacity-100 translate-y-0"
      leaveFromClass="opacity-100 translate-y-0"
      leaveActiveClass="transition duration-0"
      leaveToClass="opacity-0 -translate-y-4"
    >
      <HeaderContentSearchHistory
        v-if="isDisplaySearchContent && searchStore.isSearched && isFixed"
      />
    </Transition>
  </header>
</template>

<script lang="ts" setup>
import { useWindowScroll } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { JButton, JContainer, JIcon, JLogo } from '@journey/ui-kit'
import type { Props } from '../TheHeader.types'
import type { Emits, Events, HeaderItem } from './TheHeaderContent.types'
import {
  HEADER_ITEMS,
  HEADER_OFFSET_SCROLL,
} from './TheHeaderContent.constants'
import HeaderContentSearchHistory from './HeaderContentSearchHistory/HeaderContentSearchHistory.vue'
import HeaderContentCurrencyMenu from './HeaderContentCurrencyMenu/HeaderContentCurrencyMenu.vue'
import HeaderContentProfile from './HeaderContentProfile/HeaderContentProfile.vue'
import useHelpStore from '@/modules/Help/store'
import useAuthStore from '@/modules/Auth/store'
import useDeviceType from '@/modules/Core/composables/useDevicePlatform'
import useItinerarySummary from '@/modules/Search/store/itinerarySummary'
import useSearchStore from '@/modules/Search/store'
import TopAlerts from '@/modules/Core/components/partials/TopAlerts/TopAlerts.vue'
import ItinerarySummaryToggler from '@/modules/Search/components/ItinerarySummary/ItinerarySummaryToggler/ItinerarySummaryToggler.vue'

withDefaults(defineProps<Props>(), {
  showProfile: true,
  showMultiLang: true,
  showCurrency: true,
})

const emit = defineEmits<Emits>()

const router = useRouter()
const route = useRoute()
const { isDesktop } = useDeviceType()

const searchStore = useSearchStore()
const itinerarySummaryStore = useItinerarySummary()

const itineraryLength = computed(
  () => Object.values(itinerarySummaryStore.itineraries || {}).flat().length
)

// HEADER ITEMS
const { t } = useI18n()

const authStore = useAuthStore()

const localizedHeaderItems = computed(() =>
  HEADER_ITEMS.map((item) => ({
    ...item,
    title: t(item.title),
  }))
)

function onClickItem(eventName: Events, item: HeaderItem) {
  if (item.route) {
    router.push(item.route)
  } else {
    emit(eventName, item)
  }
}

// PROFILE
function emitShowProfile() {
  emit('showProfile')
}

// LOGOUT
function emitLogout() {
  emit('logout')
}

// RECENT ACTIVITY
const helpStore = useHelpStore()

// Contact Us
function emitContactUs() {
  emit('contactUs')
}

const { y } = useWindowScroll()

// const headerRef = ref<HTMLElement>()
const headerNavRef = ref<HTMLElement>()
const translateY = ref(0)
const isFixed = ref(false)

function getHeaderHeight() {
  return Number(headerNavRef.value?.offsetHeight) + HEADER_OFFSET_SCROLL || 0
}

function onScroll() {
  const headerHeight = getHeaderHeight()
  const scrollTop = y.value

  if (scrollTop >= headerHeight) {
    setFixedHeader()
    return
  }

  setScrollingHeader(scrollTop)
}

function setFixedHeader() {
  isFixed.value = true
  translateY.value = 0
}

function setScrollingHeader(scrollTop: number) {
  isFixed.value = false
  translateY.value = -scrollTop
}

const isDisplaySearchContent = computed(() => route.name === 'Search')

onMounted(() => window.addEventListener('scroll', onScroll))

onUnmounted(() => window.removeEventListener('scroll', onScroll))
</script>
